<template>
  <div>
    <el-row>
      <el-button
        v-throttle
        type="primary"
        size="medium"
        @click="callCamera"
        :disabled="isOpen"
        >开启摄像头</el-button
      >
      <el-button
        v-throttle
        type="primary"
        size="medium"
        @click="closeCamera"
        :disabled="!isOpen"
        >关闭摄像头</el-button
      >
    </el-row>

    <el-row style="margin-top: 10px">
      <el-col :span="20">
        <el-row :gutter="10">
          <el-col :span="12">
            <!--图片展示-->
            <el-card shadow="always" v-show="isOpen">
              <video ref="video" width="640" height="480" autoplay></video>
            </el-card>
          </el-col>
          <el-col :span="12">
            <!--canvas截取流-->
            <el-card shadow="always" v-show="isOpen">
              <canvas
                ref="canvas"
                width="640"
                height="480"
                @click="lookViewer"
              ></canvas>
            </el-card>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="4">
        <!--确认-->
        <div>
          <el-button
            v-throttle
            v-if="isOpen"
            size="medium"
            type="primary"
            icon="el-icon-camera"
            @click="photograph"
            style="margin-left: 10px"
            >拍照</el-button
          >
        </div>
        <!-- 重置 -->
        <div>
          <el-button
            v-throttle
            v-if="imgBase64"
            size="medium"
            type="danger"
            icon="el-icon-delete"
            @click="resetViewer"
            style="margin: 10px 0 0 10px"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-image-viewer
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="srcList"
      ></el-image-viewer>
    </el-row>
  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { isEmpty } from '@/utils/util'
export default {
  name: 'camera',
  components: { ElImageViewer },
  data() {
    return {
      isOpen: false,
      imgBase64: '',
      showViewer: false,
      srcList: [],
    }
  },
  mounted() {},
  methods: {
    //开启关闭摄像头
    callCamera() {
      const loading = this.globalLoading('正在开启摄像头,请稍后...')
      // H5调用电脑摄像头API
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((success) => {
          loading.close()
          this.isOpen = true
          // 摄像头开启成功
          this.$refs['video'].srcObject = success
          // 实时拍照效果
          this.$refs['video'].play()
        })
        .catch((error) => {
          loading.close()
          this.msgError('摄像头开启失败，请检查摄像头是否可用！或者打开摄影头')
          console.error('摄像头开启失败，请检查摄像头是否可用！')
        })
    },
    //拍照
    photograph() {
      let ctx = this.$refs['canvas'].getContext('2d')
      // 把当前视频帧内容渲染到canvas上
      ctx.drawImage(this.$refs['video'], 0, 0, 640, 480)
      // 转base64格式、图片格式转换、图片质量压缩
      let imgBase64 = this.$refs['canvas'].toDataURL('image/jpeg', 0.7) // 由字节转换为KB 判断大小
      this.imgBase64 = imgBase64
      let str = imgBase64.replace('data:image/jpeg;base64,', '')
      let strLength = str.length
      let fileLength = parseInt(strLength - (strLength / 8) * 2) // 图片尺寸  用于判断
      let size = (fileLength / 1024).toFixed(2)
      // console.log(size); // 上传拍照信息  调用接口上传图片 .........

      // 保存到本地
      /*  let ADOM = document.createElement("a");
       ADOM.href = imgBase64;
       ADOM.download = new Date().getTime() + ".jpeg";
       ADOM.click(); */
    },
    //查看图片
    lookViewer() {
      if (!isEmpty(this.imgBase64)) {
        this.srcList = []
        this.srcList.push(this.imgBase64)
      }
      if (this.srcList.length > 0) {
        this.showViewer = true
      }
    },
    //重置图片
    resetViewer() {
      this.imgBase64 = ''
      this.clearCanvas()
    },
    //清空画布
    clearCanvas() {
      let ctx = this.$refs['canvas'].getContext('2d')
      ctx.clearRect(0, 0, 640, 480)
    },
    //关闭图片
    closeViewer() {
      this.srcList = []
      this.showViewer = false
    },
    //关闭摄像头
    closeCamera() {
      if (!this.$refs['video'].srcObject) {
        return
      }
      let stream = this.$refs['video'].srcObject
      let tracks = stream.getTracks()
      tracks.forEach((track) => {
        track.stop()
      })
      this.$refs['video'].srcObject = null
      this.isOpen = false
    },
  },
}
</script>
<style></style>
